
import React from 'react'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { useRecoilValue } from 'recoil'

import firebase from 'utils/firebase'
import Spinner from 'utils/Spinner'
import useStatus from 'utils/useStatus'

import { userAtom } from 'utils/atoms'


export default function SignUpForm({
	done = () => {}
}) {

	const [status, setStatus] = useStatus()
	const { isAuth } = useRecoilValue(userAtom)
	const emailRef = React.useRef(null)	
	const passwordRef = React.useRef(null)	
	const confirmRef = React.useRef(null)	

	const clearPasswords = () => {
		confirmRef.current.value = ''
		passwordRef.current.value = ''
		passwordRef.current.focus()
	}

	const handleSubmit = async (event) => {
		event.preventDefault()
		const formData = new FormData(event.target)
		if (formData.get('password') !== formData.get('confirm')) {
			clearPasswords()
			setStatus({ error: "Passwords don't match. Try again." })
			return
		}
		const email = formData.get('email'),
				password = formData.get('password')
		setStatus(true)
		try {
			const auth = getAuth(firebase)
			if (!!auth.currentUser) return setStatus({ error: "You're already signed in!" })
			await createUserWithEmailAndPassword(auth, email, password)
			// done()
		} catch (error) { setStatus({ error }) }
	}

	React.useEffect(() => {
		if (isAuth) done()
	}, [isAuth, done])

	return <form onSubmit={handleSubmit}>

		<h2>Sign up with email and password</h2>

		<label>
			Email:
			<input type="email"
				name='email'
				autoComplete="username"
				ref={emailRef}
				required
			/>
		</label>

		<label>
			Password:
			<input type='password'
				name='password'
				autoComplete='new-password'
				ref={passwordRef}
				required
			/>
		</label>

		<label>
			Confirm password:
			<input type='password'
				name='confirm'
				autoComplete='new-password'
				ref={confirmRef}
				required
			/>
		</label>

		<p>	
			<button type='submit' disabled={status.working}>
				Create account
			</button>
		</p>

		{ status.working && <Spinner>Creating account...</Spinner> }
		{ status.error }

	</form>
}