
import React from 'react'
import { useRecoilValue } from 'recoil'
import { navigate } from 'gatsby'
import { getAuth } from 'firebase/auth'
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore'

import Meta from 'layout/Meta'
import firebase from 'utils/firebase'
import Spinner from 'utils/Spinner'
import useStatus from 'utils/useStatus'
import SignInEmail from 'user/SignInEmail'
import SignUpEmail from 'user/SignUpEmail'
import SignInFacebook from 'user/SignInFacebook'

import { userAtom } from 'utils/atoms'


export default function CreateRoomPage() {

	const [status, setStatus] = useStatus() 
	const { uid, isAuth, isFacebookAuth } = useRecoilValue(userAtom)
	const [signInPrompt, setSignInPrompt] = React.useState(null)
	const [name, setName] = React.useState('')
	const roomNameRef = React.useRef(null)
	const [requireFacebook, setRequireFacebook] = React.useState(false)
	
	async function handleSubmit(event) {
		if (event) event.preventDefault()
		if (requireFacebook && !isFacebookAuth) return setSignInPrompt('facebook')
		if (!isAuth) return setSignInPrompt(true)
		if (!name) setStatus({ error: "Room name is required." })
		setStatus(true)
		const config = {
			name,
			admin: uid,
			users: [uid],
			created: serverTimestamp(),
			updated: serverTimestamp(),
		}
		if (requireFacebook) {
			const facebookProfile = getFacebookProfile()
			if (!facebookProfile) return setSignInPrompt('facebook')
			config.requireFacebook = true
			config.facebookProfiles = [{
				...facebookProfile,
				uid,
			}]
		}
		try {
			const firestore = getFirestore(firebase)
			const roomRef = await addDoc(collection(firestore, 'rooms'), config)
			// alert(roomRef.id)
			navigate(`/chat/${roomRef.id}/`)
		} catch (error) { setStatus({ error }) }
	}

	function handleSignIn() {
		setSignInPrompt(false)
		handleSubmit()
	}

	React.useEffect(() => {
		roomNameRef.current.focus()
	}, [roomNameRef])

	return <main>

		<Meta title="New room" />
		
		<h1>Create a new room</h1>
		
		{ !signInPrompt &&
			<form onSubmit={handleSubmit} id='CreateRoom'>

				<label>
					Room name:
					<input type='text' 
						onChange={e => setName(e.target.value) }
						aria-labelledby='NRNameLabel'
						autoComplete='off' 
						ref={roomNameRef}
						value={name} 
						id='NRName'
						required
					/>
				</label>

				<div className='checkbox'>
					<label>
						<input type='checkbox' 
							onChange={event => setRequireFacebook(event.target.checked)}
							checked={requireFacebook}
						/> Require sign in with Facebook
					</label>
					<p>Members are required to sign in with their Facebook account to join the room. All messages are still anonymous but everyone in the room knows who is in the room.</p>
				</div>

				<p>{ status.working
					?	<Spinner>Creating room</Spinner>
					:	<button type='submit' disabled={status.working}>
							Continue
						</button>
				}</p>

				{ status.error }

			</form>
		}

		{ !!signInPrompt && <>
			<p>Sign in to continue:</p>
			<SignInFacebook done={handleSignIn} />
			{ signInPrompt === true && <>
				<SignInEmail done={handleSignIn} />
				<SignUpEmail done={handleSignIn} />
			</>}
		</>}

	</main>
}


function getFacebookProfile() {
	const auth = getAuth(firebase)
	if (!auth.currentUser) return false
	const providerData = auth.currentUser.providerData.find(d => d.providerId === 'facebook.com')
	if (!providerData) return false
	return {
		name: providerData.displayName,
		id: providerData.uid,
		photo: providerData.photoURL,
	}
}
